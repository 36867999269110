<template>
  <div>
    <v-row class="justify-center align-center">
      <v-col
        cols="12"
        md="3"
        class="d-flex justify-center align-center"
        style="height: 100vh"
      >
        <v-row class="py-10 px-5">
          <v-col cols="12">
            <v-card class="mx-auto my-2 px-5 py-5" outlined style="width: 100%">
              <v-img :src="logo" class="mx-auto" style="max-width: 175px">
              </v-img>
              <h4 class="text-center my-2">
                Cadastre-se para ver fotos e vídeos dos seus amigos.
              </h4>
              <v-btn block color="info" elevation="2">
                <i class="fab fa-facebook-square mx-3"></i> Entrar com o
                Facebook</v-btn
              >
              <v-row class="my-2 justify-center align-center">
                <v-col>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="3"> OU </v-col>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="name"
                  :counter="10"
                  :rules="nameRules"
                  label="Nome Completo"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="name"
                  :counter="10"
                  :rules="nameRules"
                  label="Senha"
                  required
                ></v-text-field>

                <v-btn block color="secondary" elevation="2" class="mt-5"
                  >Cadastre-se</v-btn
                >
                <p class="my-5 text-center">
                  Ao se cadastrar, você concorda com nossos <br />
                  <router-link to="" custom>Termos, </router-link>
                  <router-link to="" custom>Política de Dados</router-link> e
                  <router-link to="" custom>Política de Cookies.</router-link>
                </p>
              </v-form>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto px-2 py-2 text-center" outlined>
              Tem uma conta?
              <router-link to="/login" custom>Entrar</router-link>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Footer />
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: require("@/assets/logo.png"),
      valid: true,
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
};
</script>
<style>
.div-circle {
  width: 50px;
  height: 50px;
  border: 2px #000 solid;
  border-top-left-radius: 100% !important;
  border-top-right-radius: 100% !important;
  border-radius: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
</style>